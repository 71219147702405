import styled from 'styled-components';
import { queries } from '../../../utils/mediaQueries';

export const Wrapper = styled.div`
  ${({ count, direction }) => {
    if (count) {
      let directionCss =
        direction === 'row'
          ? ``
          : `
            max-width: calc(100% / ${count});
            padding: 0 10px;
        `;
      return `
            ${directionCss}
            @media ${queries.md} {
                max-width: 100%!important;
                padding: 0;
              }
        `;
    }
  }}
`;

export const Container = styled.div`
  position: relative;
  ${({ active, bgColor, activeColor, hoverColor }) => {
    if (active) {
      return `
        & > div {
            background-color: ${activeColor}!important;
        }
        `;
    }

    return `
    & > div {
        background-color: ${bgColor};
    }
    &:hover {
        & > div {
            background-color: ${hoverColor};
        }
    }`;
  }}
`;

export const NoChildren = styled.div`
  padding: 50px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
`;

export const Dot = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    border-radius: 100%;
    background: white;
    cursor: pointer;

    &:after { 
        display: block;
        content: " ";
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin: 4px;
    }
    border: 1px solid transparent;

    ${({ tickboxRadius }) => `
        border-radius: ${tickboxRadius}!important;
        &:after { 
            border-radius: ${tickboxRadius}!important;
        }
    `}

    ${({ tickboxPositionTop, tickboxPositionLeft }) => {
      return `
            margin-top: ${tickboxPositionTop ?? 10}px;
            margin-left: ${tickboxPositionLeft ?? 20}px;
        `;
    }}

    ${({ active, tickboxColor, tickboxColorActive }) => {
      if (active) {
        return `border: 1px solid ${tickboxColorActive};`;
      } else {
        return `border: 1px solid ${tickboxColor};`;
      }
    }}

    ${({ active, tickboxColor, tickboxColorActive }) => {
      if (active) {
        return `
            &:after { 
                background: ${tickboxColorActive || tickboxColor}!important;
            }`;
      } else {
        return ``;
      }
    }}
`;
